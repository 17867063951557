@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue-55-cufonfonts/HelveticaNeueUltraLightItal.ttf') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue-55-cufonfonts/HelveticaNeue-UltraLightItalic.woff2') format('woff2'),
       url('./assets/fonts/helvetica-neue-55-cufonfonts/HelveticaNeue-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  font-weight: 200;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
