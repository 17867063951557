.App {
  height: 100vh;
  width: 100vw;
  background-image: url('../assets/bangos.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bangos-text {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 50;
    font-style: italic;
    font-size: 240px;
    color: rgb(182, 253, 255);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    letter-spacing: 0px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    max-width: 100%;
    text-align: center;
    line-height: 125px;
    hyphens: none;
}
